.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.radio-group {
  display: flex;
  justify-content: space-between;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: none;
}

.radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  display: none;
}

.radio-group .ant-radio-button-wrapper:last-child,
.radio-group .ant-radio-button-wrapper:first-child {
  border-radius: 4px;
}

.radio-group .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #ccc;
}

.radio-group .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child:hover {
  border-right-color: rgb(62, 121, 247)
}

.radio-group  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: none;
  border-width: 2px;
  border-color: #ccc;
  border-radius: 4px;
}

.radio-button {
  width: 50px;
  height: 50px;
  border-radius: 4px;
}

@media(max-width: 768px) {
  .radio-button {
      width: 40px;
      height: 40px;
  }
}

.danger-color {
  color: #FB6376;
}
.gap-10 {
  gap: 10px;
}